// Nube Models v2.0 (210921)
export function Price() {
    return {
        PICKUP: 0,
        DELIVERY: 0,
    }
}

export function Item() {
    return {
        id: null,
        merchantId: null,
        name: '',
    }
}

export function ExtraItem() {
    return {
        id: null,
        merchantId: null,
        name: '',
        price: Price(), // 取代takeawayPrice, deliveryPrice
        isDefault: false,
    }
}

export function Extra() {
    return {
        id: null,
        merchantId: null,
        name: '',
        items: [],
        minSelected: 1,
        maxSelected: 1,
    }
}

export function Product() {
    return {
        id: null,
        merchantId: null,
        name: '',
        description: '',
        image: null,
        price: Price(), // 取代takeawayPrice, deliveryPrice
        extras: [],
        soldOutAtShopIds: [], // 取代isSoldOut
    }
}

export function Category() {
    return {
        id: null,
        merchantId: null,
        name: '',
        products: [],
    }
}

export function Customer() {
    return {
        id: null,
        uid: null,
        merchantId: null,
        name: '',
        phone: '',
        gender: 'M',
        birthMonth: 1,
        pointBalance: 0,
        accumulatedPoints: 0,
    }
}

export function Discount() {
    return {
        schema: 2, // schema v2
        id: null,
        merchantId: null,
        code: '',
        name: '',
        type: 'AMOUNT', // 折扣類型 AMOUNT, PERCENTAGE
        amount: 0,
        presentAtShopIds: [], // 可顯示分店
        oncePerOrder: true, // schema v2
        onlyInOrder: true, // schema v2
    }
}

export function Shop() {
    return {
        id: null,
        merchantId: null,
        name: '',
        address: '',
        phone: '',
        image: null, // 取代coverImage
        uniNums: [],
        categories: [],
        openingHours: {
            0: [{ start: '09:00', end: '21:00' }],
            1: [{ start: '09:00', end: '21:00' }],
            2: [{ start: '09:00', end: '21:00' }],
            3: [{ start: '09:00', end: '21:00' }],
            4: [{ start: '09:00', end: '21:00' }],
            5: [{ start: '09:00', end: '21:00' }],
            6: [{ start: '09:00', end: '21:00' }],
        },
        orderMethods: ['PICKUP'], // 分店訂單方式
        paymentMethods: ['CASH'], // 分店付款方式
        reservationDays: 0,
        readyTime: 20,
        busyTime: 40,
        timeInterval: 15, // 分店預訂間距
        isBusy: false,
        isClosed: false,
    }
}

export function OrderShop({ shop }) {
    return {
        id: shop.id,
        name: shop.name,
        phone: shop.phone,
        address: shop.address,
    }
}

export function OrderCustomer({ customer }) {
    return {
        id: customer.id,
        name: customer.name, 
        phone: customer.phone,
        address: customer.address,
    }
}

export function OrderProduct({ product, items, count, total }) {
    return {
        id: product.id,
        name: product.name,
        price: product.price,
        count,
        items,
        total,
        remark: '',
    }
}

export function Order({ type = 'NORMAL', source = 'POS', method = 'PICKUP', status = 'COMPLETED' }) {
    return {
        schema: 2, // schema v2
        id: null,
        merchantId: null,
        merchantName: null,
        shop: null,
        uniNum: null,
        type, // 訂單類型 NORMAL, RESERVE, ASAP. 移除了reservation
        source, // 訂單來源 POS, BYOD, ADMIN
        method, // 訂單方式 PICKUP, DELIVERY
        customer: {
            address: '',
        },
        products: [],
        productTotal: 0,
        // discount: null,
        discounts: [],
        discountTotal: 0,
        grandTotal: 0,
        payment: {
            method: null,
            paid: 0,
        },
        remark: '',
        status, // 訂單狀態 RECEIVED, PROCESSING, PACKED, COMPLETED, CANCELLED, PROCESSING_PAYMENT, PENDING_PAYMENT, VOID
        reservationAt: null,
        pendingAt: null,
        receivedAt: null,
        packedAt: null,
        completedAt: null,
        processingPaymentAt: null,
        pendingPaymentAt: null,
        voidAt: null,
    }
}

export function Merchant() {
    return {
        id: null,
        code: '',
        name: '',
    }
}