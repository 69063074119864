<template>
    <button class="popup-close-btn" @click="path ? $router.push(`/${shop.id}/${path}`) : $router.go(-1)">
        <img src="@/assets/icons/ic-close-circle-white.svg" />
    </button>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['path'],

    computed: {
        ...mapState({ shop: state => state.shop })
    }
}
</script>