import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'
import { Order } from '@/models'
import _ from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        shop: null,
        customer: null,
        cart: Order({ type: 'RESERVE', source: 'BYOD', status: 'PENDING' }),

        discounts: null,
        orders: null,
    },
    mutations: {
		...vuexfireMutations,

        // 新增購物車商品
        addCartProduct({ cart }, { product }) {
            cart.products.push(product)
        },

        // 修改購物車商品
        editCartProduct({ cart }, { product, index }) {
            let products = _.cloneDeep(cart.products)
            products.splice(index, 1, product)
            cart.products = products
        },

        // 移除購物車商品
        removeCartProduct({ cart }, { index }) {
            cart.products.splice(index, 1)
        },

        // 更新購物車總計
        updateCartTotals({ cart }) {
            cart.productTotal = 0
            cart.products.forEach(product => {
                product.total = Math.round((product.price[cart.method] + _.sumBy(product.items, item => { return item.price[cart.method] })) * product.count * 10) / 10
                cart.productTotal += product.total
            })
            cart.productTotal = Math.round((cart.productTotal) * 10) / 10

            cart.discountTotal = 0

            cart.discounts.forEach(discount => {
                if (discount.type == 'AMOUNT') {
                    discount.total = discount.amount
                    cart.discountTotal += discount.total         
                } else {
                    discount.total = (cart.productTotal - cart.discountTotal) * (discount.amount / 100)
                    cart.discountTotal += discount.total
                }
            })
            cart.discountTotal = Math.round((cart.discountTotal) * 10) / 10

            cart.grandTotal = Math.round((cart.productTotal - cart.discountTotal) * 10) / 10
        },

        // 設定購物車
        setCart(state, { order }) {
            state.cart = order
        },

        // 重設購物車
        resetCart(state) {
            state.cart = Order({ type: 'RESERVE', source: 'BYOD', status: 'PENDING' })
        },

        // 新增購物車折扣
        addCartDiscount({ cart }, { discount }) {
            cart.discounts.push(discount)
        },
        
        // 移除購物車折扣
        removeCartDiscount({ cart }, { index }) {
            cart.discounts.splice(index, 1)
        },

        // 設定購物車折扣
        setCartDiscount({ cart }, { discounts }) {
            cart.discounts = discounts
        }
	},
    actions: {
        bindCollectionByMerchant: firestoreAction((context, { col, merchantId }) => {
			if (context.state[col]) return true
			return context.bindFirestoreRef(col, db.collection(col).where('merchantId', '==', merchantId))
		}),

        bindCollectionByCustomer: firestoreAction((context, { col, customerId }) => {
			if (context.state[col]) return true
			return context.bindFirestoreRef(col, db.collection(col).where('customer.id', '==', customerId))
		}),

        bindDocument: firestoreAction((context, { col, id, target }) => {
            if (context.state[target] && context.state[target].id == id) return true
			return context.bindFirestoreRef(target, db.collection(col).doc(id))
		}),

        unbindState: firestoreAction((context, { item }) => {
			if (!context.state[item]) return true
			return context.unbindFirestoreRef(item, () => null)
		}),

        createDocument(store, { col, doc }) {
            let ref = db.collection(col)
			if(!doc.id) doc.id = ref.doc().id
			return ref.doc(doc.id).set(doc).then(() => {
				return doc
			}).catch(err => {
				console.error(err)
				return false
			})
		},

        getDocument({ state }, { col, id }) {
			if (!state[col]) return false
			return Object.assign({}, state[col].find(item => item.id === id))
		},

        // 新增購物車商品
        addCartProduct({ commit }, { product }) {
            commit('addCartProduct', { product })
            commit('updateCartTotals')
        },

        // 移除購物車商品
        removeCartProduct({ commit }, { index }) {
            commit('removeCartProduct', { index })
            commit('updateCartTotals')
        },

        // 新增購物車折扣
        addCartDiscount({ commit }, { discount }) {
            commit('setCartDiscount', { discounts: [discount] })
            commit('updateCartTotals')
        },

        // 移除購物車折扣
        removeCartDiscount({ commit }, { index }) {
            commit('removeCartDiscount', { index })
            commit('updateCartTotals')
        },
        
        // 更新購物車總計
        updateCartTotals({ commit }) {
            commit('updateCartTotals')
        },

        // 重設購物車
        resetCart({ commit }) {
            commit('resetCart')
        },

        // 建立訂單
        async createOrder({ state, dispatch }) {
            let createOrder = functions.httpsCallable('createOrder')
            const result = await createOrder({ order: state.cart })
            dispatch('resetCart')
            return result.data
        },
    },
    getters: {
        getProduct: (state) => (id) => {
            let res = null
            state.shop.categories.forEach((category) => {
                category.products.forEach((product) => {
                    if (product.id == id) return res = product
                })
            })
            return res
        }
    }
})