import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/error',
        component: () => import('@/views/Error.vue'),
    },
    {
        path: '/:shopId',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/:shopId/home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/:shopId/info',
        component: () => import('@/views/Info.vue'),
    },
    {
        path: '/:shopId/time',
        component: () => import('@/views/Time.vue'),
    },
    {
        path: '/:shopId/product/:id',
        component: () => import('@/views/Product.vue'),
    },
    {
        path: '/:shopId/bag',
        component: () => import('@/views/Bag.vue'),
    },
    {
        path: '/:shopId/payment',
        component: () => import('@/views/Payment.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/:shopId/login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/:shopId/register',
        name: 'Register',
        component: () => import('@/views/Register.vue'),
        props: true,
    },
    {
        path: '/:shopId/profile',
        component: () => import('@/views/Profile.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/:shopId/history',
        component: () => import('@/views/History.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/:shopId/order/:id',
        component: () => import('@/views/Order.vue'),
    },
    {
        path: '*',
        redirect: '/error',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (to.path == '/error') return next()
    const res = await store.dispatch('bindDocument', { col: 'shops', id: to.params.shopId, target: 'shop' })
    if (!res) return next({ path: `/error` })

    if (!to.matched.some((record) => record.meta.requiresAuth)) return next()
    if (localStorage.getItem(`${store.state.shop.merchantId}-customerId`)) return next()
    return next({ path: `/${store.state.shop.id}/login` })
})

export default router
