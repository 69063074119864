import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// firebase.initializeApp({
// 	apiKey: 'AIzaSyD9aPJbzHxOT19EtLGkpeUUnWsx9lodlK8',
// 	authDomain: 'nube-b0448.firebaseapp.com',
// 	projectId: 'nube-b0448',
// 	storageBucket: 'nube-b0448.appspot.com',
// 	messagingSenderId: '483737314531',
// 	appId: '1:483737314531:web:9b4f27de479b129707d1d6',
// 	measurementId: 'G-WFR990TRK6',
// })

firebase.initializeApp({
	apiKey: 'AIzaSyBVNZwfykBNxMwPvAylRiO3dStk_I0eAEo',
	authDomain: 'nube-dev-8514f.firebaseapp.com',
	projectId: 'nube-dev-8514f',
	storageBucket: 'nube-dev-8514f.appspot.com',
	messagingSenderId: '700624211737',
	appId: '1:700624211737:web:1481baaebee0e432d13f88',
});

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.app().functions('asia-east2');
