import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/styles/custom.scss'

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

import VueScrollactive from 'vue-scrollactive'
Vue.use(VueScrollactive)

import VueQrcode from '@chenfengyuan/vue-qrcode'
Vue.component(VueQrcode.name, VueQrcode)


import Navbar from '@/components/Navbar'
Vue.component('n-navbar', Navbar)

import CloseButton from '@/components/CloseButton'
Vue.component('n-close-button', CloseButton)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
