<template>
    <div>
        <!-- HeaderBackgroud -->
        <div v-if="showMenu" class="header-background" @click="showMenu = false"></div>
        <!-- Header -->
        <header>
            <!-- MenuButton -->
            <button class="icon menu" :class="{ active: showMenu }" @click="showMenu = !showMenu">
                <div class="hamburger">
                    <img src="@/assets/icons/ic-hamburger.svg" />
                </div>
                <div class="close">
                    <img src="@/assets/icons/ic-close.svg" />
                </div>
            </button>
            <!-- Logo -->
            <!-- <router-link to="/home">
                <img src="@/assets/logo.png" height="45px" style="margin-left: 2rem; margin-top: 5px;" />
            </router-link> -->
            <div class="name">{{ shop.merchantName }} {{ shop.name }}</div>
            <!-- Social Media -->
            <button class="login">
                <div style="height: 24px; width: 74px;">
                    <a v-if="shop.facebookUrl" :href="shop.facebookUrl">
                        <img src="@/assets/icons/ic-facebook.svg" />
                    </a>
                    <a v-if="shop.instagramUsername" :href="`https://www.instagram.com/${shop.instagramUsername}`" style="margin-left: 1rem; margin-right: 10px;">
                        <img src="@/assets/icons/ic-instagram.svg" />
                    </a>
                </div>
            </button>
            <!-- Menu Links -->
            <div class="sub-menu" :class="{ active: showMenu }">
                <ul>
                    <li v-for="link in computedLinks" :key="link.id" :class="{ active: link.id === computedActiveLink }" @click="onClickMenu(link)">
                        <span>{{ link.label }}</span>
                    </li>
                </ul>
            </div>
        </header>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            showMenu: false,
            links: [
                { id: 'home', label: '主頁', type: 'path' },
                { id: 'login', label: '登入', type: 'path', },
                { id: 'profile', label: '個人檔案', type: 'path', hidden: true },
                { id: 'history', label: '訂單記錄', type: 'path', hidden: true },
                { id: 'credit', label: 'v.2.1.4', type: 'text' },
            ],
        }
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
        }),

        computedLinks() {
            return this.links.filter(link => {
                if (localStorage.getItem(`${this.shop.merchantId}-customerId`) && link.id == 'login') link.hidden = true
                if (localStorage.getItem(`${this.shop.merchantId}-customerId`) && (link.id == 'profile' || link.id == 'history')) link.hidden = false
                if (!link.hidden) return link
            })
        },

        computedActiveLink() {
            let activeLink = null
            this.links.forEach(link => {
                if (this.$route.path.indexOf(link.id) > -1) activeLink = link.id
            })
            return activeLink
        }
    },

    methods: {
        onClickMenu(link) {
            if (link.type == 'text') return
            if (this.$route.path.indexOf(link.id) < 0) return this.$router.push(`/${this.shop.id}/${link.id}`)
        }
    }
}
</script>
